import React, { useState } from 'react';
import './FAQ.css'; // CSS file for styling

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const questions = [
    {
      question: 'What is DataFlix?',
      answer: 'DataFlix is an e-wallet app that allows you to store money, make payments, and perform other financial transactions electronically through your mobile device.'
    },
    {
      question: 'How do I download and install the Dataflix app?',
      answer: 'You can download the Dataflix app from the App Store for iOS or Google Play for Android. Follow the on-screen instructions for installation.'
    },
    {
      question: 'Can I transfer money from DataFlix to my bank account?',
      answer: 'Yes, DataFlix allows you to transfer money directly to your linked bank account.'
    },
    {
      question: 'Is DataFlix compatible with all types of devices?',
      answer: 'DataFlix is compatible with most mobile devices, including iOS and Android platforms.'
    },
    {
      question: 'How can I contact DataFlix customer support if I encounter issues?',
      answer: 'You can reach DataFlix customer support via email at support@credkar.com or through the in-app chat feature.'
    }
  ];

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-section">
        <div className="faq-container">
            <div className="t1">
                        <div className="path-fire-card">
                            <svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00556 19.1145C8.93375 17.7419 9.01928 16.2593 8.26215 14.6668C8.05161 15.5212 7.66646 16.0397 7.10668 16.2225C7.6265 14.7379 7.19214 13.1341 5.80355 11.4109C5.77358 13.1906 5.34589 14.4871 4.52044 15.3003C3.3833 16.4194 3.39668 17.6825 4.56054 19.0894C-0.270846 16.5183 -0.95247 13.0699 2.51559 8.74441C2.7307 9.78938 3.25194 10.4309 4.0794 10.6691C3.17746 6.84165 4.12643 3.58041 6.92628 0.885376C6.94349 6.86626 8.77753 7.39786 11.0964 10.0676C13.6001 13.2714 12.1287 16.9687 8.00556 19.1145Z" fill="url(#paint0_linear_14_2053)"/>
                                            <defs>
                                            <linearGradient id="paint0_linear_14_2053" x1="11.9294" y1="5.88848" x2="1.26339" y2="16.5545" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#EF3E3D"/>
                                            <stop offset="1" stop-color="#FFC828"/>
                                            </linearGradient>
                                            </defs>
                            </svg>
                            <h1 className="testimonial-fire-text">Questions </h1>
                        </div>
            </div>
            <h2 className="testimonial-main-text">Frequently asked questions</h2>
            <div className="faq">
                {questions.map((item, index) => (
                <div key={index} className="faq-item">
                    <div className={`faq-question ${activeIndex === index ? 'active' : ''}`} onClick={() => toggleAnswer(index)}>
                    {item.question}
                    <span className="arrow">{activeIndex === index ? '▲' : '▼'}</span>
                    </div>
                    {activeIndex === index && <div className="faq-answer">{item.answer}</div>}
                </div>
                ))}
            </div>
        </div>
    </div>
  );
};

export default FAQ;
