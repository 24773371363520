import React from 'react';

const Header = () => {
  return (
    <header className="header">
      <div className="logo" href="\">
        <h1 className='header-credkar'><font color="#46CA61">Data</font><font color="white">Flix</font></h1>
      </div>

      <nav className="navbar">
        <ul>
          <li><a href="#products">Solutions</a></li>
          <li><a href="#process">Industries</a></li>
          <li><a href="#faq">Resources</a></li>
          <li><a href="#about">About Us</a></li>
        </ul>
      </nav>
      <a href="https://forms.gle/6jz3eJXDs5MTXtCE8">
        <button className="signInBtn">Inquire Now</button>
      </a>
      
    </header>
  );
};

export default Header;
